import Axios from "axios";
import config from "../config";

const AuthService = {
	get refreshToken() {
		let key = window.localStorage.getItem("spaceio-refresh-token");
		if (key === null) {
			return null
		};
		return key;
	},

	set refreshToken(key) {
		if (!key) return;
		window.localStorage.setItem("spaceio-refresh-token", key);
	},

	get authKey() {
		let key = window.localStorage.getItem("spaceio-key");
		if (key === null) return null
		return key;
	},

	set authKey(key) {
		if (!key) return;
		window.localStorage.setItem("spaceio-key", key);
	},

	isKeyValid() {
		const expiryTime = window.localStorage.getItem("spaceio-key-expiry");
		const now = Date.now();
		return Number(now) <= Number(expiryTime);
	},

	async fetchAuthKey() {
		let key = AuthService.authKey;
		if (AuthService.isKeyValid()) {
			return key;
		};
		const newAuthKey = await this.refreshTokenAPI(AuthService.refreshToken);
		return newAuthKey;
	},

	refreshTokenAPI: async (refreshKey) => {
		try {
			const res = await Axios({
				headers: {
					"x-refresh-token": refreshKey,
				},
				method: 'post',
				url: `${config.REACT_APP_PRODWS_AFREESPACE_URL}/api/refresh-token?requiredRefreshToken=true`
			});
			const { 'x-auth-key': newAuthKey, 'x-auth-keyexpiry': expiry, 'x-refresh-token': refreshToken } = res.headers;

			AuthService.authKey = newAuthKey;
			AuthService.refreshToken = refreshToken;
			window.localStorage.setItem('spaceio-key-expiry', Date.now() + Number(expiry));

			return newAuthKey;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	getAuthToken: async (authCode) => {
		try {
			const res = await Axios({
				headers: {
					"x-auth-code": authCode,
				},
				method: 'post',
				url: `${config.REACT_APP_PRODWS_AFREESPACE_URL}/api/login/code?requiredRefreshToken=true`
			});
			
			const { 'x-auth-key': newAuthKey, 'x-auth-keyexpiry': expiry, 'x-refresh-token': refreshToken } = res.headers;

			AuthService.authKey = newAuthKey;
			AuthService.refreshToken = refreshToken;
			window.localStorage.setItem('spaceio-key-expiry', Date.now() + Number(expiry));
			return res;
		} catch (err) {
			if(err && err.response && err.response.status === 401) {
				window.location.href = config.REACT_APP_DOMAIN_REFERRER_URL
			}
			throw (err.response.data.error)
		}
	},

	searchAPIModified: async (blessIDAndMacID, type) => {
		let myHeaders = new Headers();
		// myHeaders.append("X-REFERRER", "htts://account.dev.afreespace.com");
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("x-auth-key", await AuthService.fetchAuthKey());

		let raw = JSON.stringify(blessIDAndMacID);

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		const response = await fetch(`${config.REACT_APP_PRODWS_AFREESPACE_URL}/api/admin/devices/search?type=${type}`, requestOptions)
		const result = await response.json();
		return result;

	},

	summaryApi: async (macAddress) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + await AuthService.fetchAuthKey());

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.REACT_APP_NEW_ELECTRIC_LAMBDA_URL}summary?deviceId=${macAddress}`, requestOptions)
		const result = await response.json();
		return result;
	},
	productType: async (productId) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + await AuthService.fetchAuthKey());

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.REACT_APP_NEW_ELECTRIC_LAMBDA_URL}productmodel?productId=${productId}`, requestOptions)
		const result = await response.json();
		return result;
	},
	modelName: async (deviceId) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + await AuthService.fetchAuthKey());

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.REACT_APP_NEW_ELECTRIC_LAMBDA_URL}productdevicegroup?deviceGroupId=${deviceId}`, requestOptions)
		const result = await response.json();
		return result;
	},

	deblessedAPI: async (loginKey, macAddress, deviceId) => {
		let myHeaders = new Headers();
		myHeaders.append("x-auth-key", await AuthService.fetchAuthKey());
		myHeaders.append("content-type", "application/json");

		let raw = JSON.stringify({

			"XElectricImpKey": `${loginKey}`,
			"deviceData": { "data": [{ "type": "device", "id": `${macAddress}` }] },
		});

		let requestOptions = {
			method: 'DELETE',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};
		const response = await fetch(`${config.REACT_APP_NEW_ELECTRIC_LAMBDA_URL}debless?deviceGroupId=${deviceId}`, requestOptions)
		const result = await response.json();
		return result;
	},
	factoryEvent: async (macAddress, blessId) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + await AuthService.fetchAuthKey());

		const raw = JSON.stringify([
			{
				"macId": `${macAddress}`,
				"blessId": blessId
			}
		]);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		};

		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}factory_event`, requestOptions)
		const result = await response.json();
		return result;
	},
	deviceConfig: async (macAddress, blessId) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + await AuthService.fetchAuthKey());

		const raw = JSON.stringify([
			{
				"macId": `${macAddress}`,
				"blessId": blessId
			}
		]);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		};

		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}device_config`, requestOptions)
		const result = await response.json();
		return result;
	},
	toggleDeviceConfigStatus: async (blessIds, status) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + await AuthService.fetchAuthKey());

		const payload  = JSON.stringify({
			bless_ids: blessIds,
			status
		});

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: payload,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}toggle_device_config_status`, requestOptions)
		const result = await response.json();
		return result;
	},
	fetchFactoryEvents: async (blessIds) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + await AuthService.fetchAuthKey());

		const payload  = JSON.stringify({
			bless_ids: blessIds,
		});

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: payload,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}fetch_factory_events`, requestOptions)
		const result = await response.json();
		return result;
	},
	storeFactoryEvents: async (data) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + await AuthService.fetchAuthKey());

		const payload  = JSON.stringify(data);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: payload,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}store_factory_events`, requestOptions)
		const result = await response.json();
		return result;
	},
	event: async (data) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("x-api-key", config.REACT_APP_FACTORY_KEY);

		const payload  = JSON.stringify(data);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: payload,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}event`, requestOptions)
		const result = await response.json();
		return result;
	},
	fetchProductType: async (productType) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + await AuthService.fetchAuthKey());

		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}fetch_product_type?product_type=${productType}`, requestOptions)
		const result = await response.json();
		return result;
	},
	items: async (hardwareDeviceId, data) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("x-api-key", config.REACT_APP_FACTORY_KEY);

		const payload  = JSON.stringify(data);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: payload,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}items?item_mac=${hardwareDeviceId}`, requestOptions)
		const result = await response.json();
		return result;
	},
}
export default AuthService;