import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import DeblessedUpload from './pages/deviceDeblessed/deblessedUpload';
import Authenticate from './components/AuthUI';
import Approver from './pages/Approver/approver';
import Requester from './pages/Requester/requester';

const RouteElement = ({ location, from, children }) => {
  const { pathname } = location
  const LoggedinFlag = window.localStorage.getItem('spaceio-key')
  return pathname === '/login' ? (LoggedinFlag ?
    <Navigate to={from} /> : (children)) : (LoggedinFlag ? children :
      <Navigate to={'/login'} state={{ from: location }} />)
}

const ProtectedRoute = ({ children, path }) => {
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }
  return <RouteElement location={location} from={from} caseSensitive>{children}</RouteElement>
}

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<Authenticate />}></Route>
        <Route path='login' element={<ProtectedRoute><Authenticate /></ProtectedRoute>}></Route>
        <Route path='upload' element={<ProtectedRoute><DeblessedUpload /></ProtectedRoute>}></Route>
        <Route path='requester' element={<ProtectedRoute><Requester /></ProtectedRoute>}></Route>
      <Route path='approver' element={<ProtectedRoute><Approver /></ProtectedRoute>}></Route>
    </Routes>
    </Router >
  );
}

export default App;
