import AuthService from "../Auth/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";

function Authenticate() {
    const params = new URLSearchParams(window.location.search)
    const navigate = useNavigate();
    const authCode = params.has('xauthcode') ? params.get('xauthcode') : null
    useEffect(() => {
        async function fetchToken() {
            await AuthService.getAuthToken(authCode);
            // let fetchRole = localStorage.getItem("spaceio-user")
            // if (fetchRole) {
            //     try {
            //         const userObject = JSON.parse(fetchRole);
            //         const roles = userObject.roles || [];

            //         // const hasFactoryAdminRole = roles.some(data => data.title.toLowerCase() === 'factory admin');
            //         // const hasBlesserRole = roles.some(data => data.title.toLowerCase() === 'blesser');
            //         const hasFactoryAdminRole = roles.some(data => data.title.toLowerCase() === 'super admin (read only)');
            //         const hasBlesserRole = roles.some(data => data.title.toLowerCase() === 'office User');

            //         if (hasFactoryAdminRole && hasBlesserRole) {
            //             navigate('/approver');
            //         } else if (hasFactoryAdminRole && !hasBlesserRole) {
            //             navigate('/requester');
            //         } else {
            //             window.location.href = 'https://login.afreespace.com/v2?referrer=https://admin-temp.afreespace.com/controls.html';
            //         }


            //     } catch (error) {
            //         console.error("Error parsing user data:", error);
            //     }
            // } else {
            //     console.log("User data not found in local storage.");
            // }
            // navigate('/approver')
            navigate('/requester')
        }
        fetchToken();
    }, [authCode])

    if (!params.has('xauthcode')) {
        // dev login or dev domain else prod login or prod domain
        window.location.href = `${config.REACT_APP_LOGIN_API_URL}/v2?referrer=${config.REACT_APP_DOMAIN_REFERRER_URL}`;
    }
}



export default Authenticate;